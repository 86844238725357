import {  fetchit } from 'lib'

import {
    API_STATS
} from 'config/api'


export function getStats(accessToken) {
    const url = `${API_STATS}`
    return fetchit(url, null, 'GET', accessToken)
}

