import { Navigate } from "react-router-dom";
import { useState } from "react";
import { useMutation } from "react-query";
import { loginOrRegister } from "./services";
import { REDIRECT_PATH } from "config/paths";
import useUser from "hooks/useUser";
import useUuid from "hooks/useUuid";
import { Title } from "components/Typography";
import { Button, Input } from "components/Form";
import { Spacing } from "components/Layout/Spacing";
import { NoDataFound } from "components/LottieFiles";
import { Row, Column } from "components/Layout/Styles";
import { Container, LoginBox, Wrapper, RightSide, Error, Message } from "./Style";
import LottieLogin from './LottieLogin'

export default function LoginComponent() {
  const user = useUser((state) => state.user);
  const uniqueId = useUuid((state) => state.uuid);
  const [email, setEmail] = useState();
  const mutation = useMutation(() => loginOrRegister(email, uniqueId));


  const handleSubmit = (e) => {
    e.preventDefault();
    if (!!email) {
      mutation.mutate();
    }
  };

  const handleEmailInput = (e) => setEmail(e.target.value, uniqueId);

  if (!!user?.user?.email) {
    return <Navigate to={REDIRECT_PATH} replace />;
  }

  return (
    <Row>
      <Column>
        <Container>
          <LoginBox>
          
            <Title>{mutation.isSuccess ? "Check Your Inbox ✉️" : "Sign in"}</Title>
            {
              !mutation.isSuccess &&  <form onSubmit={handleSubmit}>
              <Wrapper>
                <Input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  onChange={handleEmailInput}
                />
              </Wrapper>
              <Spacing bottom="sm" />
              <Wrapper>
                <Button type="submit" disabled={!email} secondary={ mutation.isSuccess }>
                  {!mutation.isSuccess &&
                  !mutation.isError &&
                  !mutation.isLoading
                    ? "Continue"
                    : null}
                 
                  {mutation.isLoading ? <NoDataFound/> : null}
                </Button>
                <Spacing bottom="md" />
                <Spacing bottom="sm" />
                {mutation.isError ? <Error>❌🤓 {mutation.error.message}</Error> : null}
              
              </Wrapper>
            </form>
            }
             {mutation.isSuccess ? <Message onClick={() => window.location.reload()}>Try again?</Message> : null}
          </LoginBox>
        </Container>
      </Column>
      <Column>
        <RightSide alert={mutation.isError && !mutation.isSuccess}>
          <LottieLogin />
       </RightSide>
       
      </Column>
    </Row>
  );
}
