import { fetchit } from 'lib'
import {
    API_AUTH_LOGIN,
    API_AUTH_LOGOUT,
    API_AUTH_REFRESH_TOKENS,
    API_AUTH_AUTHORIZE,
    API_AUTH_REDIRECT_URI
} from 'config/api'


export function loginOrRegister(email) {
    return fetchit(API_AUTH_LOGIN, { email, redirectURI: API_AUTH_REDIRECT_URI },'POST') 
}

export function refreshToken(refreshToken) {
    return fetchit(API_AUTH_REFRESH_TOKENS, { refreshToken },'POST')
}

export function authorize(email) {
    return fetchit(API_AUTH_AUTHORIZE, { email },'POST')
}

export function logout(refreshToken) {
    return fetchit(API_AUTH_LOGOUT, { refreshToken },'POST')
}