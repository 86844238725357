import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import { Row } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import { Input, Button, Error, TextArea, MultiSelect } from "components/Form";
import ModalGallery from "routes/media/ModalGallery";
import Gallery from "routes/media/Gallery";

export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm,
  lessonList,
  assessmentList,
}) {
  const { t } = useTranslation();
  const [medias, setMedias] = useState([]);
  const [showLessons, setShowLessons] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [published, setPublished] = useState(false);
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = (data) => {
    const mediaIds =
      medias && medias[0] && medias[0]._id ? medias.map((m) => m._id) : null;
    const documentIds =
      documents && documents[0] && documents[0]._id
        ? documents.map((m) => m._id)
        : null;
        

    delete data.tags;
    const lessons = data?.lessons ? data.lessons.map((s) => s.value) : [];
    const assessments = data?.assessments ? data.assessments.map((s) => s.value) : [];
    onSubmit({
      ...data,
      published,
      medias: mediaIds,
      documents: documentIds,
      lessons,
      assessments
    });
  };

  const handleMedias = (data) => {
    setMedias(data);
  };

  const handleDocuments = (data) => {
    setDocuments(data);
  };

  useEffect(() => {
    if (!!defaultValues?.medias) {
      setMedias(defaultValues.medias);
    }
    if (!!defaultValues?.documents) {
      setDocuments(defaultValues.documents);
    }
    if (!!defaultValues?.published) {
      setPublished(defaultValues.published);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (Array.isArray(defaultValues?.lessons)) {
      const lessonList = defaultValues.lessons.map((r) => {
        return { label: r.title, value: r._id };
      });

      setValue("lessons", lessonList);
    }

    if (Array.isArray(defaultValues?.assessments)) {
      const assessmentList = defaultValues.assessments.map((r) => {
        return { label: r.title, value: r._id };
      });

      setValue("assessments", assessmentList);
    }


    setShowLessons(true);
  }, [defaultValues, setValue]);

  const lessonsOptions =
    lessonList && lessonList[0]
      ? lessonList.map((cat) => {
          return { value: cat._id, label: cat.title };
        })
      : null;
  
  const assessmentsOptions =
  assessmentList && assessmentList[0]
    ? assessmentList.map((cat) => {
        return { value: cat._id, label: cat.title };
      })
    : null;

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        <Spacing bottom={"lg"} />
        <Row>
          <label>{t("form.title")}</label>
          <Input
            {...register("title", { required: true })}
            aria-invalid={errors.title ? "true" : "false"}
            placeholder={t("form.title")}
          />
          {errors.title && <Error>Title Name is required</Error>}
        </Row>
        <Row>

        <label>{t("form.duration")}</label>
          <Input
            {...register("duration", { required: false })}
            aria-invalid={errors.duration ? "true" : "false"}
            placeholder={t("form.duration")}
            type="number"
          />
          {errors.name && <Error>Duration Name is required</Error>}
        </Row>
        <Row>
        <label>{t("form.description")}</label>
          <TextArea
            {...register("description")}
            aria-invalid={errors.description ? "true" : "false"}
            placeholder={t("form.description")}
            rows={10}
          />
        </Row>
        <Spacing top="md" />
        {lessonsOptions && showLessons && (
          <Row>
            <label>Séléction des leçons</label>

            <Controller
              name="lessons"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  control={control}
                  isMulti
                  options={lessonsOptions}
                  placeholder={t("lessons")}
                  {...field}
                />
              )}
            />
          </Row>
        )}

<Spacing top="md" />
        {assessmentsOptions && showLessons && (
          <Row>
            <label>Séléction des évaluations</label>

            <Controller
              name="assessments"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  control={control}
                  isMulti
                  options={assessmentsOptions}
                  placeholder={t("assessments")}
                  {...field}
                />
              )}
            />
          </Row>
        )}

        <Spacing bottom={"lg"} />
        <label>Medias</label>
        <ModalGallery isMany onClick={handleMedias} />
        <Spacing bottom={"lg"} />
        <Gallery medias={medias} small />
        <Spacing bottom={"lg"} />

        <Spacing bottom={"lg"} />
        <label>Documents</label>
        <ModalGallery isMany onClick={handleDocuments} />
        <Spacing bottom={"lg"} />
        <Gallery medias={documents} small />
        <Spacing bottom={"lg"} />

        <Button type="submit">{buttonLabel}</Button>
      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
