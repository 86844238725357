import {  fetchit } from 'lib'

import {
    API_UPLOAD
} from 'config/api'

export function getLatestMedia({accessToken}) {
    return fetchit(API_UPLOAD+'/?sortBy=createdAt:desc&limit=100', null, 'GET', accessToken)
}

