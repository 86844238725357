import {  fetchit } from 'lib'

import {
    API_QUESTION
} from 'config/api'


export function createQuestion(data, accessToken) {
    return fetchit(API_QUESTION, data, 'POST', accessToken)
}

export function editQuestion(data, questionId, accessToken) {
    return fetchit(API_QUESTION+'/'+questionId, data, 'PATCH', accessToken)
}

export function getQuestions({ accessToken, limit=8, page=0 }) {
    const url = `${API_QUESTION}/?limit=${limit}&page=${page}`
    return fetchit(url, null, 'GET', accessToken)
}

export function deleteQuestion(questionId, accessToken) {
    if(!questionId) return
    return fetchit(API_QUESTION+'/'+questionId, null, 'DELETE', accessToken)
}
