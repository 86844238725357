import styled from "styled-components";

export const Ul = styled.ul`
  display: block;
  background: transparent;
`;

export const Li = styled.li`
  opacity: ${props=>props.reduceOpacity ? .5 : 1};
  display: flex;
  justify-content: flex-start;
  list-style: none;
  padding: 15px 10px;
  position: relative;
  background-color: #fbf6ff;
  cursor: pointer;
  transition: all 0.14s ease-in-out;
  border:1px solid rgba(0,0,0,.05);
  border-right: none;
  border-left:none;
  border-top:none;
  
  width: 100%;
  font-family: ${(props) => props.theme.font.body};
  font-weight: 500;
  :hover {
    background-color: #f7f7f7;
  }
  &:first-child {
    border-top: 1px solid rgba(0,0,0,.05);
  }
`;

export const Item = styled.span`
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
  margin-right: 50px;
  font-weight:800;
  padding: 5px;
  border-radius:7px;
  background-color: white ;
  transition: all .1s ease-in-out ;
 
  :hover {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  }
`;

export const TagContainer = styled.div`
  position: absolute;
  left: 30%;
  @media (max-width: 767px) {
    display: none;
  }

`;

export const TagItems = styled.span`

  padding: 7px;
  border-radius: 8px;
  margin-top: -5px;
  margin-right: 5px;
  font-size: 12px ;
  font-weight:100 ;
  border: 1px dashed rgba(255,255,255,.5);
  background-color: ${(p) => (p.backgroundColor ? p.backgroundColor : "white")};
  color: ${(p) => (p.color ? p.color : "black")};
`;


export const DictionaryContainer = styled.div`
  position: absolute;
  right: 103px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.05s;
  :hover {
    border-bottom: 1px solid ${(props) => props.theme.colors.danger};
  }
`;

export const RightIcon = styled.div`
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
`;

export const LeftIcon = styled.div`
  position: absolute;
  left: 13px;
  top: 50%;
  transform: translateY(-50%);
`;
