import { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Card from "./Card";

const Gallery = ({ medias, onClick=()=>{}, isMany, small }) => {
  const [selected, setSelected] = useState([]);
 
  function handleClick(item) {
    if (isMany) {
      if (selected.map(s=>s.filename).indexOf(item.filename) > -1) {
        const selectedFileNames = selected.filter(s => s.filename !== item.filename)
        onClick(selectedFileNames);
        setSelected(selectedFileNames)
        return
      }
      onClick([...selected, item]);
      setSelected([...selected, item]);
    } else {
      onClick(item);
    }
  }
  if (!medias || !medias[0]) {
    return <div />;
  }
  return (
    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}>
      <Masonry gutter="10px">
        {medias.map((m, k) => (
          <Card
            small={ small }
            isMany={isMany}
            media={m}
            key={"card-" + k}
            onClick={()=>handleClick(m)}
            isSelected={(selected.map(s=>s.filename).indexOf(m.filename) > -1)}
          />
        ))}
      </Masonry>
    </ResponsiveMasonry>
  );
};

export default Gallery;
