import {  fetchit } from 'lib'

import {
    API_USERS
} from 'config/api'


export function createUser(data, accessToken) {
    return fetchit(API_USERS, data, 'POST', accessToken)
}

export function editUser(data, userId, accessToken) {
    return fetchit(API_USERS+'/'+userId, data, 'PATCH', accessToken)
}

export function getUsers({ accessToken, limit=8, page=0, email=null, role=null }) {
    let url = `${API_USERS}/?limit=${limit}&page=${page}&populate=profilePicture,profileCover&sortBy=lastLoggedIn:desc`
    if (email) {
        url = url + '&email='+email
    }
    if (role) {
        url = url +'&role='+role
    }
    return fetchit(url, null, 'GET', accessToken)
}

export function deleteUser(userId, accessToken) {
    if(!userId) return
    return fetchit(API_USERS+'/'+userId, null, 'DELETE', accessToken)
}
