const removeNil = (o) => {
    let out = {}
    for (const [key, value] of Object.entries(o)) {
        if(!!value || value === false) out[key]=value
    }
    delete out['_id']
    delete out['createdAt']
    delete out['updatedAt']
    delete out['createdBy']
    delete out['__v']
    delete out['lastLoggedIn']
    delete out['shortId']
    delete out['updatedBy']
    return out
};

export default removeNil