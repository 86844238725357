import React, { useMemo } from "react";
import { FilePond } from "react-filepond";
import Gallery from "./Gallery";
import { useTranslation } from "react-i18next";
import { ContentContainer } from "components/Layout/Styles";
import useUser from "hooks/useUser";
import { useQueryClient, useQuery } from "react-query";
import { getLatestMedia } from "./services";
import { API_UPLOAD } from "config/api";
import { GET_MEDIAS } from "config/queries";
import { Button } from "components/Form";
import "filepond/dist/filepond.min.css";

export default function AssetsComponent({
  onClick,
  isMany = false,
  setVisible,
  isVideo
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { access } = useUser((state) => state.user);

  const medias = useQuery(GET_MEDIAS, () => {
      return getLatestMedia({
        accessToken: access.token,
      });
  });

  function invalidateQuery() {
    queryClient.invalidateQueries(GET_MEDIAS);
  }

  const mediaList = useMemo(() => {
    const m = medias?.data?.results
    if (isVideo && Array.isArray(m)) {
      return m.filter(m=>m.mimetype==='video/mp4')
    }
    
    return m
  },[medias, isVideo])

  return (
    <>
      <div style={{marginBottom:'60px'}} />
      <FilePond
        onprocessfile={invalidateQuery}
        allowMultiple={true}
        maxFiles={1}
        server={`${API_UPLOAD}`}
        name="file"
        labelIdle={t("form.upload")}
        credits={false}
      />

      <ContentContainer>
        <Gallery
          medias={mediaList}
          onClick={onClick}
          isMany={isMany}
        />
        {
          isMany &&  <Button
          style={{ position: "fixed", bottom: 0, right: 0, maxWidth:'400px' }}
          onClick={() => setVisible(false)}
        >
          {t("btn.done")}
        </Button>
        }
       
      </ContentContainer>
    </>
  );
}
