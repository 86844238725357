import styled from "styled-components";

export const Title = styled.h1`
 color: ${p => p.dark ? p.theme.colors.light.contrastedText : p.theme.colors.light.text};
 line-height: 1.5em;
 letter-spacing: .05em;
 font-size: 1.5rem
`


export const Text = styled.p`
 color: ${p => p.dark ? p.theme.colors.light.contrastedText : p.theme.colors.light.text};
 line-height: 1.5em;
 letter-spacing: .05em ;
`

export const SyntaxContainer = styled.div`
    background-color: #f5f6f7;
    padding: 0px;
    padding-left: 15px;
    margin-top: 10px;
    border-radius:15px;
    cursor:copy ;
    border: 5px dashed rgba(0,0,0,.2);
    transition: all .5s ;
    max-width: 600px;
    :hover{
        border: 5px dashed rgba(0,0,0,.5);

    }

`

export const TextAlert = styled.h3`

    color: ${p => p.color};
    line-height: 1.5em;
    letter-spacing: .05em;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.5em;
    font-weight: 500;
    transition: all .5s ;
    // animation
    animation: ${p => p.color === "red" ? "shake 1s cubic-bezier(.36,.07,.19,.97) both" : "none"};
`