export const API_BASE_URL = 'https://marrakech-headless.fly.dev/v1/'
export const API_AUTH_REDIRECT_URI = process.env.NODE_ENV !== 'production' ? 'http://localhost:3000/auth/refresh-tokens' : 'https://gestion.cnt.academy/auth/refresh-tokens'
export const UPLOAD_CDN = "https://concierge.fra1.cdn.digitaloceanspaces.com/" 
export const DEFAULT_DOC_URL ="https://fra1.digitaloceanspaces.com/team.marrakech.io/1662056408707.jpg"
export const API_AUTH_LOGIN = API_BASE_URL + 'auth/login'
export const API_AUTH_LOGOUT = API_BASE_URL + 'auth/logout'
export const API_AUTH_REFRESH_TOKENS = API_BASE_URL + 'auth/refresh-tokens'
export const API_AUTH_AUTHORIZE = API_BASE_URL + 'auth/authorize'
export const API_HOTEL = API_BASE_URL + 'hotel'
export const API_STAFF = API_HOTEL + '/staff'
export const API_DEPARTMENT = API_BASE_URL + 'department'
export const API_ROOM = API_BASE_URL + 'room'
export const API_UPLOAD = API_BASE_URL + 'upload'
export const API_PRODUCT = API_BASE_URL + 'product'
export const API_CATEGORY = API_BASE_URL + 'category'
export const API_STATS = API_BASE_URL + 'completed/stats'
export const API_LESSON = API_BASE_URL + 'lesson'
export const API_COURSE = API_BASE_URL + 'course'
export const API_MODULE = API_BASE_URL + 'module'
export const API_ASSESSMENT = API_BASE_URL + 'assessment'
export const API_QUESTION = API_BASE_URL + 'question'
export const API_ORDER = API_BASE_URL + 'order'
export const API_RESERVATION = API_BASE_URL + 'reservation'
export const API_EMAIL_MARKETING = API_BASE_URL + 'email-marketing'
export const API_REVIEW = API_BASE_URL + 'review'
export const API_PACKAGE = API_BASE_URL + 'package'
export const API_SCHEDULE = API_BASE_URL + 'calendar'
export const API_SOCIAL_MARKETING = API_BASE_URL + 'social-marketing'
export const API_FIDELITY = API_BASE_URL + 'fidelity'
export const API_USERS = API_BASE_URL + 'users'
export const API_KIOSKS = API_BASE_URL + 'kiosk'
export const API_DOCUMENTS = API_BASE_URL + 'document'
export const WIDGET_BASE_URL = 'https://kiosk.upoui.com' 

