import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "components/ProtectedRoute";
import AuthRoutes, { path as authPath } from "./auth";
import NotFoundRoutes, { path as notFoundPath } from "./404";
import PublicRoutes, { path as publicPath } from "./public";
//🔒 protected routes
import MediaRoutes, { path as mediaPath } from "./media";
import CategoryRoutes, { path as categoryPath } from "./categories";
import LessonRoutes, { path as lessonPath } from "./lessons";
import UserRoutes, { path as userPath } from "./users";
import StudentRoutes, { path as studentPath } from './students'
import AssessmentRoutes, { path as assessmentPath } from './assessments'
import QuestionRoutes, { path as questionPath } from './questions'
import CourseRoutes, { path as coursePath } from './courses'
import ModuleRoutes, { path as modulePath } from './modules'
import TeacherRoutes, { path as teacherPath } from './teachers'
import StatRoutes, { path as statPath} from './statistics'

export default function RoutesComponent() {
  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
        theme="colored"
      />
      <BrowserRouter>
        <Fragment>
          <Routes>
            <Route exact path={authPath} element={<AuthRoutes />} />
            <Route exact path={publicPath} element={<PublicRoutes />} />
            <Route element={<ProtectedRoute />}>
              {/**🔒 Protected routes */}
              <Route exact path={mediaPath} element={<MediaRoutes />} />
              <Route exact path={categoryPath} element={<CategoryRoutes />} />
              <Route exact path={userPath} element={<UserRoutes />} />
    
              <Route exact path={lessonPath} element={<LessonRoutes />} />
              <Route exact path={studentPath} element={<StudentRoutes />} />
              <Route exact path={assessmentPath} element={<AssessmentRoutes />} />
              <Route exact path={questionPath} element={<QuestionRoutes />} />
              <Route exact path={coursePath} element={<CourseRoutes />} />
              <Route exact path={modulePath} element={<ModuleRoutes />} />
              <Route exact path={teacherPath} element={<TeacherRoutes />} />
              <Route exact path={statPath} element={<StatRoutes />} />
              {/**🔒 End of Protected routes */}
            </Route>
            <Route path={notFoundPath} element={<NotFoundRoutes />} />
          </Routes>
        </Fragment>
      </BrowserRouter>
    </>
  );
}
