import { useEffect, useRef } from "react";
import styled from "styled-components";
import { Overlay, ModalContainer } from "components/Layout/Styles";
import DeleteIcon from "components/Icons/DeleteIcon";

//import useOutside from "hooks/useOutside";
import useKeyPress from "hooks/useKeyPress";

const Modal = ({ children, visible, setVisible=()=>{} }) => {
  const wrapperRef = useRef(null);
  const pressEsc = useKeyPress('Escape')
  /*
  useOutside(wrapperRef, onClickOutside);

  function onClickOutside() {
    setVisible(false);
  }
  */

  useEffect(() => {
    if (pressEsc) {
      setVisible(false);
    }
    // eslint-disable-next-line
  },[pressEsc])

  return (
    <>
      {visible ? (
        <Overlay>
         
          <ModalContainer ref={wrapperRef}>
            <CloseModalContainer onClick={()=>setVisible(false)}>
              <DeleteIcon color={'black'} />
            </CloseModalContainer>
         
            {children}
          
          </ModalContainer>
        </Overlay>
      ) : null}
    </>
  );
};

export default Modal

const CloseModalContainer = styled.div`
  position: fixed;
  top:0px;
  right:40px;
  cursor: pointer;
  background:#dbdbdb ;
  width:50px;
  height:50px;
  border-radius:50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:999999999999999
`