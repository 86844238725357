import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import useUser from "hooks/useUser";
import {
  getLessons,
  deleteLesson,
  createLesson,
  editLesson,
} from "./services";
import {
  getCategories
} from '../categories/services'
import {
  getUsers,
} from "../users/services";
import removeNil from "lib/removeNil";
import NoDataFound from "components/LottieFiles/NoDataFound"
import { Spacing } from "components/Layout/Spacing";
import { Title } from "components/Typography";
import Layout from "components/Layout";
import Modal from "components/Layout/Modal";
import Form from "./Form";
import List from "components/List";
import { Button } from "components/Form";
import { FixedTop } from "components/Layout/Styles";
import { GET_LESSONS, GET_CATEGORIES, GET_USERS } from "config/queries";

export default function App() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { access } = useUser((state) => state.user);

  const [state, setState] = useState({
    page: 0,
    overlay: { visible: false, new: false },
    currentLesson: null,
    resetForm: false,
  });

  useEffect(() => {
    if (!state.overlay.visible) {
      setState(prevState => ({
        ...prevState,
        currentLesson: null
      }));
    }
  }, [state.overlay.visible]);


  const teacherList = useQuery([GET_USERS], () =>
  getUsers({
    accessToken: access.token,
    page: 0,
    role:'teacher',
    limit:50
  })
);

  const lessonList = useQuery([GET_LESSONS, state.page], () =>
    getLessons({
      page: state.page,
    })
  );

  const categoryList = useQuery([GET_CATEGORIES], () =>
    getCategories({
    accessToken: access.token,
    page: 0,
    limit:500
    })
  );


  const mutationOptions = {
    onSuccess: () => {
      toast.success(t("update.success"));
      queryClient.invalidateQueries(GET_LESSONS);
      setState((prevState) => ({
        ...prevState,
        resetForm: true,
        overlay: { visible: false, new: false },
        currentLesson: null
      }));
    },
    onError: () => {
      toast.error(t("update.error"));
    },
  };

  const onNewMutation = useMutation((data) => createLesson(data, access.token), mutationOptions);
  const onEditMutation = useMutation((data) => editLesson(data, state.currentLesson._id, access.token), mutationOptions);
  const onDeleteMutation = useMutation((categoryId) => deleteLesson(categoryId, access.token), {
    ...mutationOptions,
    onError: (err) => {
      toast.error(t("update.error"));
      console.error(err);
    },
  });

  const onNew = useCallback(async (data) => {
    onNewMutation.mutate(removeNil({ ...data }));
  }, [onNewMutation]);

  const onEdit = useCallback(async (data) => {
    onEditMutation.mutate(removeNil({ ...data }));
  }, [onEditMutation]);

  const onDelete = useCallback((categoryId) => {
    onDeleteMutation.mutate(categoryId);
  }, [onDeleteMutation]);

  const onListClick = useCallback((item) => {
    setState((prevState) => ({
      ...prevState,
      overlay: { visible: true, new: false },
      currentLesson: item,
    }));
  }, []);

  return (
    <Layout>
      <Spacing bottom={"lg"} />
      <Title>{t("lessons")}</Title>

      {lessonList?.data?.results && lessonList.data.results[0] ? (
        <List
          totalPages={lessonList.data.totalPages}
          currentPage={state.page}
          onChangePage={(number) => setState((prevState) => ({ ...prevState, page: number }))}
          items={lessonList.data.results}
          onDeleteBtn={onDelete}
          onClick={onListClick}
        />
      ) : <NoDataFound />
        
    }

      <FixedTop>
        <Button onClick={() => setState((prevState) => ({ ...prevState, overlay: { visible: true, new: true } }))}>
          {t("form.add")} {t("lesson")}
        </Button>
      </FixedTop>

      <Modal
        visible={state.overlay.visible}
        setVisible={(visible) => setState((prevState) => ({ ...prevState, overlay: { visible, new: prevState.overlay.new } }))}
      >
        <Form
          onSubmit={state.overlay.new ? onNew : onEdit}
          buttonLabel={t(state.overlay.new ? "form.save" : "form.edit")}
          resetForm={state.resetForm}
          defaultValues={state.currentLesson}
          categoryList={categoryList?.data?.results}
          teacherList={teacherList?.data?.results}
        />
      </Modal>
    </Layout>
  );
}