import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { refreshToken } from "./services";
import useUser from "hooks/useUser";
import { toast } from 'react-toastify';
import { REDIRECT_PATH, AUTH_PATH } from "config/paths";
import { LINK_EXPIRED, WELCOME_BACK } from "config/messages";

export default function RefreshTokensComponent() {
  const [error, setError] = useState(null)
  const user = useUser((state) => state.user);
  const setUser = useUser((state) => state.setUser);
  const params = useParams();
  const { token } = params;
  let navigate = useNavigate();

  const mutation = useMutation(() => refreshToken(token), {
    onSuccess(data) {
      data && data.user && setUser(data)
    },
    onError(error) {
      setError(error)
    }
  });


  useEffect(() => {
    if (!!token) {
      mutation.mutate();
    }
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    if (!!user?.user?.email) {
      toast.success(WELCOME_BACK)
      navigate(REDIRECT_PATH)
    }
  }, [user, navigate]);

  useEffect(() => {
    if (!!error) {
      toast.error(LINK_EXPIRED)
      navigate(AUTH_PATH);
    }
  },[error, navigate])

  return <div>Processing...</div>;
}
