import PropTypes from "prop-types";
import NavigationComponent from "components/Navigation";
import useUser from "hooks/useUser";
import Logo from "components/Logo"
import dPackage from '../../../package.json'
import {
  MenuSideBar,
  Content,
  Container,
  Wrapper,
  GreetingUser,
  SideBarFooter
} from "./Styles";
import NavigationItems from "generated/menuItems.json";

const Layout = ({
  hide = false,
  children,
  hideLeft = false,
  hideRight = false,
}) => {
  const { user } = useUser((state) => state.user);

  return (
    <>
      <Wrapper>
        {
          !hide && (
            <Container>
              {!hideLeft ? (
                <MenuSideBar>
                 <Logo />
                  <GreetingUser> {user.firstname}  </GreetingUser>
               
                  <NavigationComponent items={NavigationItems} />
                 
                
                  <SideBarFooter>Marrakech v-{dPackage.version}</SideBarFooter>
                </MenuSideBar>
              ) : null}

              <Content fullscreen={hideLeft || hideRight }>
                {children}
              </Content>
            
            </Container>
          )
        }
       
      </Wrapper>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hide: PropTypes.bool,
  hideLeft: PropTypes.bool,
  hideRight: PropTypes.bool,
  RightContent: PropTypes.element,
  LeftContent: PropTypes.element,
};

export default Layout;
