import create from 'zustand'
import omit from 'lodash-es/omit'

const useUuid = create(
    (set) => ({
      uuid: null,
      setUuid: (data) => set({ uuid: data }),
      deleteUuid: () => set((state) => omit(state, ['uuid']), true),
    })
)

export default useUuid
