import { Player } from "@lottiefiles/react-lottie-player";
import loading from 'lotties/loading.json'

export default function NoDataFound() {
  return (
    <Player
      autoplay
      loop
      src={loading}
      style={{ height: "500px", width: "500px" }}
    />
  );
}
