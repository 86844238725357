import * as React from "react"

const marrakechLogo = (props) => (
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="50pt" height="50pt" viewBox="0 0 300.000000 300.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M470 1741 l0 -250 33 -22 c17 -13 34 -27 35 -31 2 -4 8 -8 14 -8 6 0
23 -10 38 -22 64 -52 76 -60 98 -71 12 -5 22 -14 22 -19 0 -4 5 -8 11 -8 10 0
69 -41 97 -68 8 -6 23 -17 35 -23 11 -7 26 -16 31 -20 6 -5 25 -20 42 -34 18
-14 35 -25 39 -25 5 0 26 -15 49 -33 38 -30 75 -57 114 -82 9 -5 23 -16 32
-24 13 -13 15 10 18 232 2 239 3 247 22 247 11 0 20 -3 20 -7 0 -5 15 -17 32
-28 36 -22 54 -35 119 -82 24 -18 59 -43 77 -55 18 -13 48 -34 67 -48 19 -14
58 -41 86 -60 28 -19 66 -46 84 -60 19 -14 50 -36 70 -50 20 -14 56 -40 81
-57 24 -18 49 -33 54 -33 6 0 10 88 10 240 0 159 4 240 10 240 15 0 56 -27
116 -75 18 -14 37 -25 43 -25 6 0 11 -4 11 -8 0 -4 15 -16 33 -27 17 -10 39
-26 47 -35 8 -9 22 -20 30 -23 26 -12 117 -75 144 -99 11 -10 28 -20 38 -24
10 -3 18 -10 18 -15 0 -5 7 -9 15 -9 8 0 15 -3 15 -8 0 -4 30 -27 68 -52 37
-25 69 -47 72 -50 3 -3 17 -13 33 -23 l27 -17 0 253 0 254 -25 13 c-14 7 -25
17 -25 22 0 4 -5 8 -10 8 -6 0 -43 24 -83 52 -85 62 -83 61 -123 93 -18 14
-35 25 -39 25 -6 0 -61 39 -92 65 -6 6 -27 20 -45 33 -18 12 -84 58 -146 102
-62 44 -117 80 -122 80 -6 0 -10 -91 -10 -238 0 -131 -3 -242 -6 -245 -4 -4
-20 4 -36 16 -53 39 -84 61 -139 98 -53 35 -143 102 -184 135 -11 9 -30 21
-42 27 -13 5 -23 14 -23 19 0 4 -5 8 -10 8 -9 0 -148 96 -160 110 -3 4 -20 15
-37 25 -18 11 -33 22 -33 26 0 4 -9 9 -20 12 -19 5 -20 -1 -22 -236 -2 -149
-7 -242 -13 -244 -5 -1 -26 11 -45 27 -19 17 -43 33 -52 36 -10 3 -18 10 -18
15 0 5 -5 9 -11 9 -9 0 -76 45 -89 61 -3 3 -20 14 -37 24 -18 11 -33 23 -33
27 0 4 -6 8 -13 8 -8 0 -24 11 -37 25 -13 14 -28 25 -32 25 -9 0 -76 47 -88
61 -3 3 -20 14 -37 24 -18 11 -33 23 -33 27 0 4 -5 8 -10 8 -6 0 -44 25 -85
55 -40 30 -76 55 -79 55 -4 0 -6 -112 -6 -249z"/>
</g>
</svg>




)

export default marrakechLogo
