import { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm, useFieldArray } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import { Row } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";

import { Input, Select, Button, Error } from "components/Form";

const types = [
  "single-select",
  "multi-select",
  "text",
  "ordering",
  "true-false",
];

export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm,
}) {
  const { t } = useTranslation();
  const {
    register,
    reset,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "answers",
  });

  const watchAnswers = watch("answers");

  const onSubmitHelper = (data) => {
    const { answers } = data;

    onSubmit({ ...data, answers });
  };

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        <Row>
          <label>Une question?</label>
          <Input
            {...register("text", { required: true })}
            aria-invalid={errors.name ? "true" : "false"}
            placeholder={t("form.name")}
          />
          {errors.name && <Error>Question name is required</Error>}
        </Row>

        <Spacing bottom={"sm"} />
        <label>Type de question</label>
        <Select {...register(`type`)} small>
          {types.map((t, i) => (
            <option value={t} key={t + i + 99}>
              {t}
            </option>
          ))}
        </Select>

        <Spacing bottom={"sm"} />
        {fields.map((item, index) => (
          <div
            key={item + index}
            style={{
              background: "#25123670",
              marginBottom: "20px",
              padding: "15px",
              border: "3px solid black",
            }}
          >
            <label>Réponses possibles</label>
            <Input
              placeholder="Réponse"
              {...register(`answers.${index}.text`)}
            />
            <input
              type="radio"
              {...register(`answers.${index}.correct`)}
              value={true}
              defaultChecked={!!watchAnswers[index].correct}
            />
            Bonne réponse
            <input
              type="radio"
              {...register(`answers.${index}.correct`)}
              value={false}
              defaultChecked={!watchAnswers[index].correct}
            />
            Mauvaise réponse
            <Spacing bottom={"md"} />
            <label>Ordre</label>
            <Input
              type="number"
              min={0}
              {...register(`answers.${index}.order`, { valueAsNumber: true })}
              small
              placeholder="Ordre"
              defaultValue={0}
            />
            <Spacing bottom={"sm"} />
            <Button type="button" onClick={() => remove(index)} secondary>
              {t("form.delete")}
            </Button>
            <Spacing bottom={"lg"} />
          </div>
        ))}

        <Button
          secondary
          width="300px"
          type="button"
          onClick={() => {
            append();
          }}
        >
          {t("form.add")} {t("answer")}
        </Button>

        <Spacing bottom={"lg"} />

        <Button type="submit">{buttonLabel}</Button>
      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
