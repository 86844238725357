import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";
import RoutesComponent from "./routes";
import GlobalStyle from "components/GlobalStyle";
import theme from "./theme";

export default function App() {
  const queryClient = new QueryClient();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <RoutesComponent />
      </QueryClientProvider>
    </ThemeProvider>
  );
}
