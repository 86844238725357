import React from "react";
import Layout from "components/Layout";
import MediaGallery from "./MediaGallery";

export default function Previews() {
  return (
    <Layout>
      <MediaGallery />
    </Layout>
  );
}
