import React from 'react';
import {
  Navigate,
} from 'react-router-dom';
import { useQuery } from 'react-query';
import { getStats } from './services';
import useUser from "hooks/useUser";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, LineChart, Line, PieChart, Cell, Pie } from 'recharts';
import { SimpleStat } from 'components/StatCard';
import Layout from 'components/Layout';
import { NoDataFound } from 'components/LottieFiles';
import { GET_STATS } from 'config/queries';
import { LOGOUT_PATH } from "config/paths"

const COLORS = ['#003f5c', '#58508d', '#bc5090', '#ff6361', '#ffa600'];

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export default function StatsVisualization() {
  const { data: stats, isLoading, isError } = useQuery([GET_STATS],()=> getStats(access.token));
  const { access } = useUser((state) => state.user);

  if (isLoading) return <NoDataFound/>;
  if (isError) {
    return <Navigate to={LOGOUT_PATH} replace />;
  };

  const pieData = [
    { name: 'Completed Modules', value: stats.completedModules },
    { name: 'Courses Taken', value: stats.coursesTaken },
    { name: 'Lessons Taken', value: stats.lessonsTaken },
    { name: 'Assessments Taken', value: stats.assessmentsTaken },
  ];

  return (
    <Layout>
<SimpleStat>
    Un total de {stats.activeUsers} utilisateurs actifs ont terminé {stats.completedModules} modules.
    Le taux d'avancement moyen sur la plateforme est de {Math.round(stats.averageCompletion)} %,
    avec {stats.coursesTaken} cours achevés, {stats.lessonsTaken} leçons prises et {stats.assessmentsTaken} évaluations réussies.
</SimpleStat>
      
      <h3>Active Users Trend</h3>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={stats.activeUsersByMonths}>
          <XAxis dataKey="month" tickFormatter={(month) => monthNames[month - 1]} />
          <YAxis />
          <Tooltip />
          <Bar dataKey="totalActiveUsers" fill="#8884d8" />
          <Legend />
        </BarChart>
      </ResponsiveContainer>

     
        

      <h3>Activities Distribution</h3>
      <ResponsiveContainer width="100%" height={600}>
        <PieChart>
          <Pie data={pieData} cx="50%" cy="50%" outerRadius={200} fill="#8884d8" dataKey="value" label>
            {pieData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
      <h3>Learning Activities Overview</h3>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={stats.learningsbyMonths}>
          <XAxis dataKey="month" tickFormatter={(month) => monthNames[month - 1]} />
          <YAxis />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="totalCourses"
            stroke="#8884d8"
            name="Total Courses"
          />
          <Line
            type="monotone"
            dataKey="totalLessons"
            stroke="#82ca9d"
            name="Total Lessons"
          />
          <Line
            type="monotone"
            dataKey="totalAssessments"
            stroke="#ffc658"
            name="Total Assessments"
          />
          <Legend />
        </LineChart>
      </ResponsiveContainer>
      
    </Layout>
  );
}