import {
    Navigate,
  Outlet 
} from 'react-router-dom';
import useUser from "hooks/useUser"
import { AUTH_PATH } from "config/paths"
  
export default function ProtectedRoute({ redirectPath = AUTH_PATH, children }) {
  const user = useUser(state => state.user)

    if (!user || !user.user ) {
      return <Navigate to={redirectPath} replace />;
  }

    return children ? children : <Outlet />;
};
