import { Link } from "react-router-dom";
import { AUTH_PATH } from 'config/paths'
import { Title } from "components/Typography";

export default function App() {
  return (
    <>
      <Title>404 Not found</Title>
      <Link to={AUTH_PATH}>Need a ride Home?</Link>
    </>
  );
}