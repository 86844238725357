import {  fetchit } from 'lib'

import {
    API_COURSE
} from 'config/api'


export function createCourse(data, accessToken) {
    return fetchit(API_COURSE, data, 'POST', accessToken)
}

export function editCourse(data, lessonId, accessToken) {
    return fetchit(API_COURSE+'/'+lessonId, data, 'PATCH', accessToken)
}

export function getCourses({ accessToken, limit=8, page=0 }) {
    const url = `${API_COURSE}/?limit=${limit}&page=${page}&populate=medias,documents,lessons,assessments`
    return fetchit(url, null, 'GET', accessToken)
}

export function deleteCourse(lessonId, accessToken) {
    if(!lessonId) return
    return fetchit(API_COURSE+'/'+lessonId, null, 'DELETE', accessToken)
}
