import { Player } from "@lottiefiles/react-lottie-player";
import lottie from 'lotties/gallery.json'

export default function HomeLine() {
  return (
  <Player
      autoplay
      loop
      src={lottie}
      style={{  
        height:" 200px"}}
    />
  )




}
