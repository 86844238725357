import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import Toggle from "react-toggle";
import { Spacing } from "components/Layout/Spacing";
import { Row } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import { Input, Button, Error, TextArea, MultiSelect } from "components/Form";
import ModalGallery from "routes/media/ModalGallery";
import Gallery from "routes/media/Gallery";
import { Select } from "components/Form";

export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm,
  categoryList,
  teacherList,
}) {
  const { t } = useTranslation();
  const [medias, setMedias] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [published, setPublished] = useState(false);
  const [showCourses, setShowCourses] = useState(false);
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = (data) => {
    const mediaIds =
      medias && medias[0] && medias[0]._id ? medias.map((m) => m._id) : null;
      const documentIds =
      documents && documents[0] && documents[0]._id ? documents.map((m) => m._id) : null;
    delete data.tags
    const categories = data?.categories ? data.categories.map((s) => s.value) : [];
    const teachers = data?.teachers ? data.teachers.map((s) => s.value) : [];
    onSubmit({ ...data, published, medias: mediaIds, documents: documentIds, categories, teachers });
  };

  const handleMedias = (data) => {
    setMedias(data);
  };

  const handleDocuments = (data) => {
    setDocuments(data);
  };


  useEffect(() => {
    if (!!defaultValues?.medias) {
      setMedias(defaultValues.medias);
    }
    if (!!defaultValues?.documents) {
      setDocuments(defaultValues.documents);
    }
    if (!!defaultValues?.published) {
      setPublished(defaultValues.published)
    }

  }, [defaultValues]);

  useEffect(() => {
    if (Array.isArray(defaultValues?.categories)) {
      const categoryList = defaultValues.categories.map((r) => {
        return { label: r.title , value: r._id };
      });

      setValue("categories", categoryList);
    }

    if (Array.isArray(defaultValues?.teachers)) {
      const teacherList = defaultValues.teachers.map((r) => {
        return { label: r.firstname +' '+r.lastname, value: r._id };
      });

      setValue("teachers", teacherList);
    }

    setShowCourses(true);
  }, [defaultValues, setValue]);

  const categoryOptions =
  categoryList && categoryList[0]
    ? categoryList.map((cat) => {
        return { value: cat._id, label: cat.title };
      })
      : null;
  
  const teachersOptions =
  teacherList && teacherList[0]
    ? teacherList.map((cat) => {
        return { value: cat._id, label: cat.firstname +' '+cat.lastname };
      })
      : null;

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        
      <Row>
          <Toggle
            checked={published}
            id="isPublished"
            onChange={() => setPublished(!published)}
          />
          {t("published")}
        </Row>
        <Spacing bottom={"lg"} />
        <Row>
        <label>Type de leçon</label>
          <Select {...register("type")} defaultValue="" aria-invalid={errors.content ? "true" : "false"}>
            <option value="">Select type</option>
            <option value="video">Video</option>
            <option value="audio">Audio</option>
            <option value="text">Text</option>
            <option value="pdf">PDF</option>
            <option value="iframe">iFrame</option>
          </Select>
        </Row>
        <Spacing bottom={"lg"} />
        <Row>
          <label>{t("form.name")}</label>
          <Input
            {...register("title", { required: true })}
            aria-invalid={errors.title ? "true" : "false"}
            placeholder={t("form.name")}
          />
          {errors.name && <Error>Category Name is required</Error>}
        </Row>
        <Row>
          <label>{t("form.duration")}</label>
          <Input
            {...register("duration", { required: false })}
            aria-invalid={errors.duration ? "true" : "false"}
            placeholder={t("form.duration")}
            type="number"
          />
          {errors.name && <Error>Duration Name is required</Error>}
        </Row>
        <Row>
          <label>Contenu de la leçon: Texte ou Lien youtube, vimeo, soundcloud...</label>
          <TextArea
            {...register("content")}
            aria-invalid={errors.content ? "true" : "false"}
            placeholder={t("form.content")}
          />
        </Row>
        <Row>
          
        {categoryOptions && showCourses && (
          <Row>
            
            <label>Séléction des catégories</label>

            <Controller
              name="categories"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  control={control}
                  isMulti
                  options={categoryOptions}
                  placeholder={t("categories")}
                  {...field}
                />
              )}
            />
          </Row>
        )}
        </Row>
        
        <Spacing top="md" />
        {teachersOptions && showCourses && (
          <Row>
            <label>Séléction des tuteurs</label>

            <Controller
              name="teachers"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  control={control}
                  isMulti
                  options={teachersOptions}
                  placeholder={t("teachers")}
                  {...field}
                />
              )}
            />
          </Row>
        )}

       

        <Spacing bottom={"lg"} />
        <label>Fichiers médias</label>
        <ModalGallery isMany onClick={handleMedias} />
        <Spacing bottom={"lg"} />
        <Gallery medias={medias} small />
        <Spacing bottom={"lg"} />

        <Spacing bottom={"lg"} />
        <label>Documents</label>
        <ModalGallery isMany onClick={handleDocuments} />
        <Spacing bottom={"lg"} />
        <Gallery medias={documents} small />
        <Spacing bottom={"lg"} />

        <Button type="submit">{buttonLabel}</Button>
      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
