import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import fr from "./translations/fr.json";
import en from "./translations/en.json";

export const locales = [
  { name: "English", symbol: "en" },
  { name: "Français", symbol: "fr" },
];

const resources = {
  en: {
    translation: {
      ...en,
    },
  },
  fr: {
    translation: {
      ...fr,
    },
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "fr",
    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
