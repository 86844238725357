import {  fetchit } from 'lib'

import {
    API_ASSESSMENT
} from 'config/api'


export function createAssessment(data, accessToken) {
    return fetchit(API_ASSESSMENT, data, 'POST', accessToken)
}

export function editAssessment(data, lessonId, accessToken) {
    return fetchit(API_ASSESSMENT+'/'+lessonId, data, 'PATCH', accessToken)
}

export function getAssessments({ accessToken, limit=8, page=0 }) {
    const url = `${API_ASSESSMENT}/?limit=${limit}&page=${page}&populate=medias,documents,categories,teachers,questions`
    return fetchit(url, null, 'GET', accessToken)
}

export function deleteAssessment(lessonId, accessToken) {
    if(!lessonId) return
    return fetchit(API_ASSESSMENT+'/'+lessonId, null, 'DELETE', accessToken)
}
