import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import { Row } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import { Input, Button, Error, TextArea } from "components/Form";
import ModalGallery from "routes/media/ModalGallery";
import Card from "routes/media/Card";

export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm,
}) {
  const { t } = useTranslation();
  const [media, setMedia] = useState({
    "profilePicture": null,
    "profileCover": null
  });
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = (data) => {
    onSubmit({
      ...data,
      ...media,
      role: 'student'
    });
  };

  const handleProfilePicture = ((data) => {
    setMedia({...media, profilePicture: data._id});
  });

  const handleProfileCover = ((data) => {
    setMedia({...media, profileCover: data._id});
  });

  useEffect(() => {
    if (defaultValues?.profilePicture?._id) {
      handleProfilePicture(defaultValues?.profilePicture)
    }
    if (defaultValues?.profileCover?._id) {
      handleProfileCover(defaultValues?.profileCover)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
      <Row>
  {errors.role && <Error>Role is required</Error>}
        </Row>
        <Spacing bottom={"md"} />
        <Row>
          <Input
            {...register("email", { required: true })}
            aria-invalid={errors.email ? "true" : "false"}
            placeholder={t("form.email")}
          />
          {errors.firstname && <Error>firstname is required</Error>}
        </Row>
        <Spacing bottom={"md"} />
        <Row>
          <Input
            {...register("firstname", { required: false })}
            aria-invalid={errors.firstname ? "true" : "false"}
            placeholder={t("form.firstname")}
          />
          {errors.firstname && <Error>firstname is required</Error>}
        </Row>
        <Row>
          <Input
            {...register("lastname", { required: false })}
            aria-invalid={errors.lastname ? "true" : "false"}
            placeholder={t("form.lastname")}
          />
          {errors.lastname && <Error>lastname is required</Error>}
        </Row>

        <Row>
          <Input
            {...register("street", { required: false })}
            aria-invalid={errors.street ? "true" : "false"}
            placeholder={t("form.street")}
          />
          {errors.lastname && <Error>street is required</Error>}
        </Row>
        <Row>
          <Input
            {...register("city", { required: false })}
            aria-invalid={errors.city ? "true" : "false"}
            placeholder={t("form.city")}
          />
          {errors.city && <Error>city is required</Error>}
        </Row>
        <Row>
          <Input
            {...register("state", { required: false })}
            aria-invalid={errors.state ? "true" : "false"}
            placeholder={t("form.state")}
          />
          {errors.state && <Error>state is required</Error>}
        </Row>
        <Row>
          <Input
            {...register("postalCode", { required: false })}
            aria-invalid={errors.postalCode ? "true" : "false"}
            placeholder={t("form.postalCode")}
          />
          {errors.postalCode && <Error>postalCode is required</Error>}
        </Row>
        <Row>
          <Input
            {...register("companyName", { required: false })}
            aria-invalid={errors.companyName ? "true" : "false"}
            placeholder={t("form.companyName")}
          />
          {errors.companyName && <Error>Company Name is required</Error>}
        </Row>
      
        <Row>
          <Input
            {...register("phone", { required: false })}
            aria-invalid={errors.phone ? "true" : "false"}
            placeholder={t("form.phone")}
          />
          {errors.phone && <Error>phone is required</Error>}
        </Row>
        <Row>
          <Input
            {...register("whatsappPhone", { required: false })}
            aria-invalid={errors.whatsappPhone ? "true" : "false"}
            placeholder={t("form.whatsappPhone")}
          />
          {errors.whatsappPhone && <Error>Whatsapp Phone is required</Error>}
        </Row>
        <Row>
          <Input
            {...register("linkedIn", { required: false })}
            aria-invalid={errors.linkedIn ? "true" : "false"}
            placeholder={t("form.linkedIn")}
          />
          {errors.linkedIn && <Error>LinkedIn is required</Error>}
        </Row>
        <Row>
          <Input
            {...register("instagram", { required: false })}
            aria-invalid={errors.instagram ? "true" : "false"}
            placeholder={t("form.instagram")}
          />
          {errors.instagram && <Error>Instagram is required</Error>}
        </Row>
        <Row>
          <Input
            {...register("facebook", { required: false })}
            aria-invalid={errors.facebook ? "true" : "false"}
            placeholder={t("form.facebook")}
          />
          {errors.facebook && <Error>facebook is required</Error>}
        </Row>
        <Row>
          <Input
            {...register("youtube", { required: false })}
            aria-invalid={errors.youtube ? "true" : "false"}
            placeholder={t("form.youtube")}
          />
          {errors.youtube && <Error>Youtube is required</Error>}
        </Row>
        <Row>
          <Input
            {...register("website", { required: false })}
            aria-invalid={errors.website ? "true" : "false"}
            placeholder={t("form.website")}
          />
          {errors.website && <Error>Website is required</Error>}
        </Row>
        <Row>
          <Input
            {...register("jobTitle", { required: false })}
            aria-invalid={errors.jobTitle ? "true" : "false"}
            placeholder={t("form.jobTitle")}
          />
          {errors.jobTitle && <Error>Job Title is required</Error>}
        </Row>

        <Row>
          <TextArea
            {...register("bio")}
            aria-invalid={errors.bio ? "true" : "false"}
            placeholder={t("form.bio")}
            rows={15}
          />
        </Row>

        <Spacing bottom={"lg"} />
        <label>{t("form.profilePicture")}</label>
        <ModalGallery isMany={false} onClick={handleProfilePicture} />
        {defaultValues?.profilePicture &&  <Card media={defaultValues?.profilePicture} small fade/>}
       
        <Spacing bottom={"lg"} />

        <Spacing bottom={"lg"} />
        <label>{t("form.profileCover")}</label>
        <ModalGallery isMany={false} onClick={handleProfileCover} />
        {defaultValues?.profileCover &&  <Card media={defaultValues?.profileCover} small fade/>}
        <Spacing bottom={"lg"} />


        <Spacing bottom={"lg"} />
        <Button type="submit">{buttonLabel}</Button>
      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
