import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import useUser from "hooks/useUser";
import {
  getCourses,
  deleteCourse,
  createCourse,
  editCourse,
} from "./services";

import {
  getLessons,
} from "../lessons/services";

import {
  getAssessments,
} from "../assessments/services";

import removeNil from "lib/removeNil";
import NoDataFound from "components/LottieFiles/NoDataFound"
import { Spacing } from "components/Layout/Spacing";
import { Title } from "components/Typography";
import Layout from "components/Layout";
import Modal from "components/Layout/Modal";
import Form from "./Form";
import List from "components/List";
import { Button } from "components/Form";
import { FixedTop } from "components/Layout/Styles";
import { GET_LESSONS, GET_COURSES, GET_ASSESSMENTS } from "config/queries";

export default function App() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { access } = useUser((state) => state.user);

  const [state, setState] = useState({
    page: 0,
    overlay: { visible: false, new: false },
    currentCourse: null,
    resetForm: false,
  });

  useEffect(() => {
    if (!state.overlay.visible) {
      setState(prevState => ({
        ...prevState,
        currentCourse: null
      }));
    }
  }, [state.overlay.visible]);


  const courseList = useQuery([GET_COURSES, state.page], () =>
    getCourses({
      page: state.page,
    })
  );

  const lessonList = useQuery([GET_LESSONS], () =>
    getLessons({
      page: 0,
      limit:500
    })
  );

  const assessmentList = useQuery([GET_ASSESSMENTS], () =>
    getAssessments({
      accessToken: access.token,
      page: 0,
      limit:500
    })
  );

  
  const mutationOptions = {
    onSuccess: () => {
      toast.success(t("update.success"));
      queryClient.invalidateQueries(GET_COURSES);
      setState((prevState) => ({
        ...prevState,
        resetForm: true,
        overlay: { visible: false, new: false },
        currentCourse: null
      }));
    },
    onError: () => {
      toast.error(t("update.error"));
    },
  };

  const onNewMutation = useMutation((data) => createCourse(data, access.token), mutationOptions);
  const onEditMutation = useMutation((data) => editCourse(data, state.currentCourse._id, access.token), mutationOptions);
  const onDeleteMutation = useMutation((categoryId) => deleteCourse(categoryId, access.token), {
    ...mutationOptions,
    onError: (err) => {
      toast.error(t("update.error"));
      console.error(err);
    },
  });

  const onNew = useCallback(async (data) => {
    onNewMutation.mutate(removeNil({ ...data }));
  }, [onNewMutation]);

  const onEdit = useCallback(async (data) => {
    onEditMutation.mutate(removeNil({ ...data }));
  }, [onEditMutation]);

  const onDelete = useCallback((categoryId) => {
    onDeleteMutation.mutate(categoryId);
  }, [onDeleteMutation]);

  const onListClick = useCallback((item) => {
    setState((prevState) => ({
      ...prevState,
      overlay: { visible: true, new: false },
      currentCourse: item,
    }));
  }, []);

  return (
    <Layout>
      <Spacing bottom={"lg"} />
      <Title>{t("courses")}</Title>

      {courseList?.data?.results && courseList.data.results[0] ? (
        <List
          totalPages={courseList.data.totalPages}
          currentPage={state.page}
          onChangePage={(number) => setState((prevState) => ({ ...prevState, page: number }))}
          items={courseList.data.results}
          onDeleteBtn={onDelete}
          onClick={onListClick}
        />
      ) : <NoDataFound />
        
    }

      <FixedTop>
        <Button onClick={() => setState((prevState) => ({ ...prevState, overlay: { visible: true, new: true } }))}>
          {t("form.add")} {t("course")}
        </Button>
      </FixedTop>

      <Modal
        visible={state.overlay.visible}
        setVisible={(visible) => setState((prevState) => ({ ...prevState, overlay: { visible, new: prevState.overlay.new } }))}
      >
        <Form
          onSubmit={state.overlay.new ? onNew : onEdit}
          buttonLabel={t(state.overlay.new ? "form.save" : "form.edit")}
          resetForm={state.resetForm}
          defaultValues={state.currentCourse}
          lessonList={lessonList?.data?.results}
          assessmentList= {assessmentList?.data?.results}
        />
      </Modal>
    </Layout>
  );
}