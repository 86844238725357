import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import useUser from "hooks/useUser";
import {
  getCategories,
  deleteCategory,
  createCategory,
  editCategory,
} from "./services";
import removeNil from "lib/removeNil";
import NoDataFound from "components/LottieFiles/NoDataFound"
import { Spacing } from "components/Layout/Spacing";
import { Title } from "components/Typography";
import Layout from "components/Layout";
import Modal from "components/Layout/Modal";
import Form from "./Form";
import List from "components/List";
import { Button } from "components/Form";
import { FixedTop } from "components/Layout/Styles";
import { GET_CATEGORIES } from "config/queries";

export default function App() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { access } = useUser((state) => state.user);

  const [state, setState] = useState({
    page: 0,
    overlay: { visible: false, new: false },
    currentCategory: null,
    resetForm: false,
  });

  useEffect(() => {
    if (!state.overlay.visible) {
      setState(prevState => ({
        ...prevState,
        currentCategory: null
      }));
    }
  }, [state.overlay.visible]);


  const categoryList = useQuery([GET_CATEGORIES, state.page], () =>
    getCategories({
      page: state.page,
    })
  );

  const mutationOptions = {
    onSuccess: () => {
      toast.success(t("update.success"));
      queryClient.invalidateQueries(GET_CATEGORIES);
      setState((prevState) => ({
        ...prevState,
        resetForm: true,
        overlay: { visible: false, new: false },
        currentCategory: null
      }));
    },
    onError: () => {
      toast.error(t("update.error"));
    },
  };

  const onNewMutation = useMutation((data) => createCategory(data, access.token), mutationOptions);
  const onEditMutation = useMutation((data) => editCategory(data, state.currentCategory._id, access.token), mutationOptions);
  const onDeleteMutation = useMutation((categoryId) => deleteCategory(categoryId, access.token), {
    ...mutationOptions,
    onError: (err) => {
      toast.error(t("update.error"));
      console.error(err);
    },
  });

  const onNew = useCallback(async (data) => {
    onNewMutation.mutate(removeNil({ ...data }));
  }, [onNewMutation]);

  const onEdit = useCallback(async (data) => {
    onEditMutation.mutate(removeNil({ ...data }));
  }, [onEditMutation]);

  const onDelete = useCallback((categoryId) => {
    onDeleteMutation.mutate(categoryId);
  }, [onDeleteMutation]);

  const onListClick = useCallback((item) => {
    setState((prevState) => ({
      ...prevState,
      overlay: { visible: true, new: false },
      currentCategory: item,
    }));
  }, []);

  return (
    <Layout>
      <Spacing bottom={"lg"} />
      <Title>{t("categories")}</Title>

      {categoryList?.data?.results && categoryList.data.results[0] ? (
        <List
          totalPages={categoryList.data.totalPages}
          currentPage={state.page}
          onChangePage={(number) => setState((prevState) => ({ ...prevState, page: number }))}
          items={categoryList.data.results}
          onDeleteBtn={onDelete}
          onClick={onListClick}
        />
      ) : <NoDataFound />
        
    }

      <FixedTop>
        <Button onClick={() => setState((prevState) => ({ ...prevState, overlay: { visible: true, new: true } }))}>
          {t("form.add")} {t("category")}
        </Button>
      </FixedTop>

      <Modal
        visible={state.overlay.visible}
        setVisible={(visible) => setState((prevState) => ({ ...prevState, overlay: { visible, new: prevState.overlay.new } }))}
      >
        <Form
          onSubmit={state.overlay.new ? onNew : onEdit}
          buttonLabel={t(state.overlay.new ? "form.save" : "form.edit")}
          resetForm={state.resetForm}
          defaultValues={state.currentCategory}
        />
      </Modal>
    </Layout>
  );
}