import styled from "styled-components";

export const FixedBottom = styled.div`
  position:fixed;
  bottom:0;
  height:100px; 
  z-index:99;
`

export const FixedTop = styled.div`
  position:fixed;
  top:10px;
  right:30px;
  height:100px; 
  z-index:99;
  @media (max-width: 768px) {
    bottom: 0;
    top: auto;
    right:0;
    height:auto;
    width:100%;
    box-shadow: 1px 4px 16px 10px rgba(0,0,0,0.5);
  }
`

export const FixedBottomRight = styled.div`
  position:fixed;
  bottom:1px;
  right:0;
  z-index:99;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  
`;
export const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 0;
  min-height: 100vh;
  max-width: ${(p) => p.theme.screen.xxl};

`;

export const ContentContainer = styled.div`
  margin-left:20px;
  margin-right: 20px ;
  
`

export const SideBarFooter = styled.div`
color: rgba(255,255,255,.5);
font-size:10px;
`


const SideBar = styled.div`

  background-color: ${(p) => p.theme.colors.light.sidebar};
  background: linear-gradient(157deg,rgba(61,20,20,1) 0%,rgba(37,18,54,1) 40%);
  flex-grow: 1;
  max-width: ${(p) => p.theme.maxsize[1]};
  overflow-wrap: break-word;
  max-height: 100vh;
  overflow-y: scroll;
  z-index: 1;
  @media (max-width: ${(p) => p.theme.screen.md}) {
    min-width: 250px;
  }
`;

export const MenuSideBar = styled(SideBar)`
  padding: ${(p) => p.theme.spacing.sm};
  min-width: ${(p) => p.theme.maxsize[0]};
  max-width: ${(p) => p.theme.maxsize[0]};
`;

export const GreetingUser = styled.span`
    color: ${(p) => p.theme.colors.white};
    text-transform: uppercase;
    font-weight:100;
    font-size:15px

`

export const RightSideBar = styled(SideBar)`
   background-color: ${(p) => p.theme.colors.light.main};
  border-left: 1px solid  ${(p) => p.theme.colors.light.border};
  overflow-x: hidden ;
  min-width: 350px;
  max-width: 350px;
  margin-top: ${(p) => p.theme.spacing.sm};

  @media (max-width: ${(p) => p.theme.screen.sm}) {
    display: none;
  }
  @media (max-width: ${(p) => p.theme.screen.md}) {
    padding: ${(p) => p.theme.spacing.xxs};
  }
`;

export const Content = styled.div`
  flex-grow: 2;
  background-color: #fbf6ff;
  color: ${(p) => p.theme.colors.light.text};
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  word-break: break-word;
  position: relative;
  transition: .2s all;
  border-top-left-radius:0;
  padding: 25px;
  padding-top: 0;
  
`;

/**
 * Overlay, on top of the whole UI
 */
export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height:100%;
  bottom:0;
  left: 0;
  right:0;
  z-index: 999;
  background:white;
  backdrop-filter: blur(8px);
  animation: slideIn .125s cubic-bezier(0.075, 0.82, 0.165, 1);
`;

export const ModalContainer = styled.div`
  margin: 0 auto;
  padding: 50px;
  height:100%;
  max-width: 800px ;
  overflow-y: auto ;
  padding-top:10px;
  background: linear-gradient(to right, transparent 0%, rgba(255,255,255,.7) 10%, white 40%) right;
  margin-right:0 !important ;
`


export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  div:first-of-type {
    margin-right: ${(p) => p.theme.spacing.xs};
  }
  @media (max-width: ${(p) => p.theme.screen.sm}) {
    div:first-of-type {
      margin-right: 0;
    }
  }
`;

export const Column = styled.div`
  width: calc(50% - ${(p) => p.theme.spacing.xxs});
  position:relative;
  @media (max-width: ${(p) => p.theme.screen.sm}) {
    width: 100%;
  }
`;

export const Logo = styled.img`
  max-width: 50px ;
  margin:0;
  padding:0 ;
  filter: brightness(0) invert(1);
`
/**
 * Pretty print JSON
 */
export const PrettyPrintJson = ({ data }) => (
  <div>
    <pre>{JSON.stringify(data, null, 2)}</pre>
  </div>
);
