import { Player } from "@lottiefiles/react-lottie-player";
import lottie from './auth.lottie'

export default function LottieCheckEmail() {
  return (
    <Player
      autoplay
      loop
      src={ lottie }
      style={{ height: "100vh", width: "50vw" }}
    />
  );
}
