import { Routes, Route } from "react-router-dom";
import Login from "./Login";
import Logout from "./Logout";
import RefreshTokens from "./RefreshTokens";

export default function AuthRoutes() {

  return (
    <Routes>
      <Route path="refresh-tokens/:token" element={<RefreshTokens />} />
      <Route path="login" element={<Login />} />
      <Route path="logout" element={<Logout />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
}