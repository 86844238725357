import {  fetchit } from 'lib'

import {
    API_MODULE
} from 'config/api'


export function createModule(data, accessToken) {
    return fetchit(API_MODULE, data, 'POST', accessToken)
}

export function editModule(data, moduleId, accessToken) {
    return fetchit(API_MODULE+'/'+moduleId, data, 'PATCH', accessToken)
}

export function getModules({ accessToken, limit=8, page=0 }) {
    const url = `${API_MODULE}/?limit=${limit}&page=${page}&populate=medias,courses,documents,teachers,categories`
    return fetchit(url, null, 'GET', accessToken)
}

export function deleteModule(moduleId, accessToken) {
    if(!moduleId) return
    return fetchit(API_MODULE+'/'+moduleId, null, 'DELETE', accessToken)
}
