import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import { Row } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import { Input, Button, Error, TextArea, MultiSelect } from "components/Form";
import ModalGallery from "routes/media/ModalGallery";
import Gallery from "routes/media/Gallery";

export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm,
  teacherList,
  categoryList,
  questionList
}) {
  const { t } = useTranslation();
  const [medias, setMedias] = useState([]);
  const [showCourses, setShowCourses] = useState(false);
  const [documents, setDocuments] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = (data) => {
    const mediaIds =
      medias && medias[0] && medias[0]._id ? medias.map((m) => m._id) : null;
    const documentIds =
      documents && documents[0] && documents[0]._id
        ? documents.map((m) => m._id)
        : null;
        

    delete data.tags;
    const teachers = data?.teachers ? data.teachers.map((s) => s.value) : [];
    const categories = data?.categories ? data.categories.map((s) => s.value) : [];
    const questions = data?.questions ? data.questions.map((s) => s.value) : [];

    onSubmit({
      ...data,
      medias: mediaIds,
      documents: documentIds,
      teachers,
      categories,
      questions
    });

    console.log({
      ...data,
      medias: mediaIds,
      documents: documentIds,
      teachers,
      categories,
      questions
    })
  };

  const handleMedias = (data) => {
    setMedias(data);
  };

  const handleDocuments = (data) => {
    setDocuments(data);
  };

  useEffect(() => {
    if (!!defaultValues?.medias) {
      setMedias(defaultValues.medias);
    }
    if (!!defaultValues?.documents) {
      setDocuments(defaultValues.documents);
    }

  }, [defaultValues]);

  useEffect(() => {
    if (Array.isArray(defaultValues?.teachers)) {
      const teacherList = defaultValues.teachers.map((r) => {
        return { label: r.firstname +' '+r.lastname, value: r._id };
      });

      setValue("teachers", teacherList);
    }

    
    if (Array.isArray(defaultValues?.categories)) {
      const categoryList = defaultValues.categories.map((r) => {
        return { label: r.title , value: r._id };
      });

      setValue("categories", categoryList);
    }

    if (Array.isArray(defaultValues?.questions)) {
      const questionList = defaultValues.questions.map((r) => {
        return { label: r.text , value: r._id };
      });

      setValue("questions", questionList);
    }


    setShowCourses(true);
  }, [defaultValues, setValue]);

  
  const teachersOptions =
  teacherList && teacherList[0]
    ? teacherList.map((cat) => {
        return { value: cat._id, label: cat.firstname +' '+cat.lastname };
      })
      : null;
  
  const categoryOptions =
  categoryList && categoryList[0]
    ? categoryList.map((cat) => {
        return { value: cat._id, label: cat.title };
      })
      : null;
  
  const questionOptions =
  questionList && questionList[0]
    ? questionList.map((cat) => {
        return { value: cat._id, label: cat.text };
      })
      : null;
  
      

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
     
        <Spacing bottom={"lg"} />
       
        <Row>
          <label>{t("form.title")}</label>
          <Input
            {...register("title", { required: true })}
            aria-invalid={errors.title ? "true" : "false"}
            placeholder={t("form.title")}
          />
          {errors.title && <Error>Title Name is required</Error>}
        </Row>
        <Row>

        <label>{t("form.duration")}</label>
          <Input
            {...register("duration", { required: false })}
            aria-invalid={errors.duration ? "true" : "false"}
            placeholder={t("form.duration")}
            type="number"
          />
          {errors.name && <Error>Duration Name is required</Error>}
        </Row>
        <Row>
        <label>{t("form.description")}</label>
          <TextArea
            {...register("description")}
            aria-invalid={errors.presentation ? "true" : "false"}
            placeholder={t("form.fullDescription")}
            rows={5}
          />
        </Row>
        
        <Spacing top="md" />
  
        {questionOptions && showCourses && (
          <Row>
            <label>Séléction des questions</label>

            <Controller
              name="questions"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  control={control}
                  isMulti
                  options={questionOptions}
                  placeholder={t("questions")}
                  {...field}
                />
              )}
            />
          </Row>
        )}


<Spacing top="md" />
        {teachersOptions && showCourses && (
          <Row>
            <label>Séléction des tuteurs</label>

            <Controller
              name="teachers"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  control={control}
                  isMulti
                  options={teachersOptions}
                  placeholder={t("teachers")}
                  {...field}
                />
              )}
            />
          </Row>
        )}

<Spacing top="md" />
        {categoryOptions && showCourses && (
          <Row>
            <label>Séléction des catégories</label>

            <Controller
              name="categories"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  control={control}
                  isMulti
                  options={categoryOptions}
                  placeholder={t("categories")}
                  {...field}
                />
              )}
            />
          </Row>
        )}
    
    <Row>
          <label>{t("form.threshold")}</label>
          <Input
            {...register("threshold", { required: false })}
            aria-invalid={errors.threshold ? "true" : "false"}
            placeholder={t("form.threshold")}
          />
    
        </Row>
        <Spacing bottom={"lg"} />
        <label>Medias</label>
        <ModalGallery isMany onClick={handleMedias} />
        <Spacing bottom={"lg"} />
        <Gallery medias={medias} small />
        <Spacing bottom={"lg"} />

        <Spacing bottom={"lg"} />
        <label>Documents</label>
        <ModalGallery isMany onClick={handleDocuments} />
        <Spacing bottom={"lg"} />
        <Gallery medias={documents} small />
        <Spacing bottom={"lg"} />

        <Button type="submit">{buttonLabel}</Button>
      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
