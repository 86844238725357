import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { formatDistance } from 'date-fns'
import getSafe from "lib/getSafe";
import useUser from "hooks/useUser";
import {
  getUsers,
  deleteUser,
  createUser,
  editUser,
} from "../users/services";
import removeNil from "lib/removeNil";
import NoDataFound from "components/LottieFiles/NoDataFound"
import { Spacing } from "components/Layout/Spacing";
import { Title } from "components/Typography";
import Layout from "components/Layout";
import Modal from "components/Layout/Modal";
import Form from "./Form";
import { Input } from "components/Form";
import List from "components/List";
import { Button } from "components/Form";
import { FixedTop } from "components/Layout/Styles";
import { GET_USERS } from "config/queries";

export default function App() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { access } = useUser((state) => state.user);

  const [state, setState] = useState({
    page: 0,
    overlay: { visible: false, new: false },
    currentUser: null,
    resetForm: false,
  });

  const [emailFitler, setEmailFilter] = useState(null);

  useEffect(() => {
    if (!state.overlay.visible) {
      setState(prevState => ({
        ...prevState,
        currentUser: null
      }));
    }
  }, [state.overlay.visible]);


  const userList = useQuery([GET_USERS, state.page, emailFitler], () =>
    getUsers({
      page: state.page,
      accessToken: access.token,
      email: emailFitler,
      role: 'student'
    })
  );

  const mutationOptions = {
    onSuccess: () => {
      toast.success(t("update.success"));
      queryClient.invalidateQueries(GET_USERS);
      setState((prevState) => ({
        ...prevState,
        resetForm: true,
        overlay: { visible: false, new: false },
        currentUser: null
      }));
    },
    onError: () => {
      toast.error(t("update.error"));
    },
  };

  const onNewMutation = useMutation((data) => createUser(data, access.token), mutationOptions);
  const onEditMutation = useMutation((data) => editUser(data, state.currentUser._id, access.token), mutationOptions);
  const onDeleteMutation = useMutation((userId) => deleteUser(userId, access.token), {
    ...mutationOptions,
    onError: (err) => {
      toast.error(t("update.error"));
      console.error(err);
    },
  });

  const onNew = useCallback(async (data) => {
    onNewMutation.mutate(removeNil({ ...data }));
  }, [onNewMutation]);

  const onEdit = useCallback(async (data) => {
    const { tags, ...dataWithoutTags } = data;
    onEditMutation.mutate(removeNil({ ...dataWithoutTags }));
  }, [onEditMutation]);

  const onDelete = useCallback((userId) => {
    onDeleteMutation.mutate(userId);
  }, [onDeleteMutation]);

  const onListClick = useCallback((item) => {
    setState((prevState) => ({
      ...prevState,
      overlay: { visible: true, new: false },
      currentUser: item,
    }));
  }, []);

  function handleFilterChange(e) {
    if (e.key === 'Enter') {
      setEmailFilter(e.target.value)
    }
  }

  return (
    <Layout>
      <Spacing bottom={"lg"} />
      <Title>{t("students")}</Title>
      <Spacing bottom={"lg"} />
      <Input placeholder="Filtrer par adresse email puis appuyez sur Entrer" onKeyDown={handleFilterChange} type="email"/>
      {userList?.data?.results && userList.data.results[0] ? (
        <List
          totalPages={userList.data.totalPages}
          currentPage={state.page}
          onChangePage={(number) => setState((prevState) => ({ ...prevState, page: number }))}
          items={userList.data.results.map(u => {
            return {
              ...u, tags: [
                u.role,
                getSafe(()=>formatDistance(new Date(u.lastLoggedIn), new Date(), { addSuffix: true })) || 'Never'
              ]
            }
          })}
          onDeleteBtn={onDelete}
          onClick={onListClick}
        />
      ) : <NoDataFound />
        
    }

      <FixedTop>
        <Button onClick={() => setState((prevState) => ({ ...prevState, overlay: { visible: true, new: true } }))}>
          {t("form.add")} {t("student")}
        </Button>
      </FixedTop>

      <Modal
        visible={state.overlay.visible}
        setVisible={(visible) => setState((prevState) => ({ ...prevState, overlay: { visible, new: prevState.overlay.new } }))}
      >
        <Form
          onSubmit={state.overlay.new ? onNew : onEdit}
          buttonLabel={t(state.overlay.new ? "form.save" : "form.edit")}
          resetForm={state.resetForm}
          defaultValues={state.currentUser}
        />
      </Modal>
    </Layout>
  );
}